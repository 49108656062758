.siteHeader {
	--background-color: var(--navigation-color-background);
	--color: var(--navigation-color-text);

	align-items: center;
	background-color: var(--background-color);
	color: var(--color);
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	z-index: 100;

	&::after {
		background-color: rgba(0, 0, 0, 0.5);
		bottom: 0;
		content: "";
		display: block;
		height: 100vh;
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: opacity 0.3s ease-in-out;
		visibility: hidden;
		z-index: -1;
	}
}

.siteHeaderOverlay::after {
	opacity: 1;
	visibility: visible;
}

.siteHeaderContainer {
	align-items: center;
	background-color: var(--background-color);
	border-bottom: var(--navigation-border-width) var(--navigation-border-style) var(--navigation-color-border);
	display: flex;
	height: var(--header-height);
	justify-content: space-between;
	position: relative;
	width: 100%;
	z-index: 1;

	@media (--bp-large) {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-inline: var(--spacing-s);
	}
}

.siteHeaderLink {
	color: var(--navigation-color-link);
	display: flex;
	margin-left: auto;
	margin-right: auto;

	& img {
		height: 32px;
		width: 123px;

		@media (--bp-large) {
			height: 50px;
			width: 193px;
		}
	}
}

.siteHeaderNewsletterButton {
	--color: var(--navigation-color-text);
	--column-gap: 0.5rem;
	--font-family: var(--typography-body-family);
	--font-size: 0.75rem;
	--line-height: 1.36;
	--font-weight: var(--typography-btn-weight);

	align-items: center;
	appearance: none;
	background-color: transparent;
	border: 0;
	color: var(--color);
	column-gap: var(--column-gap);
	cursor: pointer;
	display: inline-flex;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	left: var(--spacing-2xs);
	line-height: var(--line-height);
	padding: 0;
	position: absolute;
	text-transform: uppercase;

	&:hover {
		text-decoration: underline;
	}

	@media (--bp-large) {
		left: var(--spacing-s);
	}
}

.siteHeaderNewsletterButtonText {
	display: none;

	@media (--bp-large) {
		display: inline;
	}
}

.siteHeaderMenuButton {
	appearance: none;
	background-color: transparent;
	border: 0;
	color: var(--navigation-color-link);
	cursor: pointer;
	display: inline-flex;
	padding-inline: 0;
	position: absolute;
	right: var(--spacing-2xs);

	@media (--bp-large) {
		display: none;
		right: var(--spacing-s);
	}
}

.siteHeaderNewsletterButtonClose {
	--font-family: var(--typography-body-family);
	--font-size: 0.75rem;
	--font-weight: var(--typography-btn-weight);

	align-items: center;
	appearance: none;
	background-color: transparent;
	border: 0;
	color: inherit; /* Fixes and issue on iOS - Chrome */
	cursor: pointer;
	display: inline-flex;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: 0.5rem;
	padding: 0;
	position: absolute;
	right: var(--spacing-s);
	text-transform: uppercase;
}

.siteHeaderSearchTrigger {
	position: absolute;
	right: var(--spacing-xl);
}

.siteHeaderSearchTrigger button {
	background-color: transparent;
}
